import request from '@/utils/request'

// 获取创建社区所需--meb的值和转账地址
export const querryPayInfo = () => {
  return request('GET', '/app/community/index')
}
// 社区信息
export const queryCommunityInfo = (params) => {
  return request('GET', '/app/community/info', params)
}

// 创建社区
export const queryCreate = (params) => {
  return request('POST', '/app/community/add', params)
}

// 社区列表
export const queryCommunityList = (params) => {
  return request('GET', '/app/community/list', params)
}

// 申请加入社区
export const queryApplyJoin = (id) => {
  const params = {
    id: id
  }
  return request('POST', '/app/community/apply', params)
}

// 修改社区信息
export const queryEditCommunity = (params) => {
  return request('POST', '/app/community/edit', params)
}

// 社区活动补贴信息查询
export const queryActiveInfo = (params) => {
  return request('GET', '/app/subsidy/index', params)
}

// 领取社区补贴活动奖励
export const queryActiveReceive = (type) => {
  const params = {
    type: type
  }
  return request('GET', '/app/subsidy/receive', params)
}

<template>
  <div class="list-wrapper">
    <div class="header">
      <div class="banner" @click="handleToMDAO">
        <img
          v-if="mbLang === 'EN'"
          src="../../../static/img/community/en-community-banner.png"
          alt=""
        />
        <img
          v-else
          src="../../../static/img/community/zh-community-banner.png"
          alt=""
        />
      </div>
      <!-- 搜索框 -->
      <div class="search-group">
        <input
          type="text"
          :confirm="handleSearch"
          v-model="searchValue"
          :placeholder="community.searchNotice"
          @keyup.enter="handleSearch"
        />
        <i class="iconfont icon-search" @click="handleSearch"></i>
      </div>
    </div>
    <!-- 社区列表 -->
    <van-list class="list-box" @load="onReachBottom">
      <div class="list-title">{{ community.communityList }}</div>
      <div class="list-content">
        <div
          class="content-item"
          v-for="item in list"
          :key="item.id"
          @click="handleToDetail(item)"
        >
          <div class="info-box">
            <div class="logo">
              <img :src="item.logo" v-if="item.logo" />
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
          <van-icon name="arrow" class="arrow" />
        </div>
        <div class="finish-data">{{ community.allLoaded }}</div>
      </div>
      <div class="btn-groups" v-if="!hideCreateBtn">
        <vanButton @click.stop="handleToCreate">{{
          community.createCommunity
        }}</vanButton>
      </div>
    </van-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: '',
  props: {
    list: {
      default () {
        return []
      }
    },
    nftLevel: {
      default () {
        return 0
      }
    },
    hideCreateBtn: {
      default () {
        return 0
      }
    }
  },
  data () {
    return {
      searchValue: '',
      isLoading: false,
      params: {
        id: '',
        pageSize: 10,
        pageNumber: 1
      }
    }
  },
  computed: {
    ...mapState(['mbLang']),
    community () {
      return this.$t('community')
    }
  },
  methods: {
    handleSearch () {
      this.$emit('searchById', this.searchValue)
    },
    handleToDetail (item) {
      this.$gbUtils.handleToPage(`/communityDetail?communityNo=${item.communityNo}`)
    },
    handleToCreate () {
      if (!this.nftLevel) return this.$toast(this.community.tips.nftRequire)
      this.$gbUtils.handleToPage('/createCommunity')
    },
    handleToMDAO () {
      window.open('https://mdao.meblox.io/')
    },
    onReachBottom () {
    }
  },
  mounted () { }
}
</script>

<style scoped lang="scss">
.list-wrapper {
  .banner {
    border-radius: 10px;
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
  .header {
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .search-group {
    box-sizing: border-box;
    // height: 75px;
    display: flex;
    align-items: center;
    padding: 13px 20px;
    position: relative;
    input {
      display: block;
      width: 100%;
      height: 50px;
      background-color: transparent;
      border: 1px solid #c1c1c1;
      border-radius: 10px;
      margin: 0 auto;
      padding: 0 20px;
      box-sizing: border-box;
    }
    .icon-search {
      position: absolute;
      right: 36px;
      color: #c1c1c1;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .list-box {
    width: 100%;
    min-height: 502px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 10px;
    .list-title {
      line-height: 29px;
      color: #16172a;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .content-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      padding: 10px 0;
      cursor: pointer;
      .info-box {
        display: flex;
        align-items: center;
        .logo {
          width: 40px;
          height: 40px;
          background: rgba(255, 255, 255, 0.39);
          border: 1px solid #707070;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 17px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .name {
          height: 22px;
          font-size: 15px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          line-height: 22px;
          color: #16172a;
          opacity: 1;
        }
      }
      .arrow {
        color: #c1c1c1;
        font-size: 24px;
      }
    }
  }
  .list-content {
    min-height: 400px;
  }
  .finish-data {
    text-align: center;
    color: #818086;
    margin-top: 10px;
  }
  .btn-groups {
    button {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      background: #a85ffc;
    }
  }
}
</style>

<template>
    <div class="list-wrap">
    <CommunityList ref="communityList" :list="list" hideCreateBtn="1" :nftLevel="0" @searchById="searchById"></CommunityList>
    </div>
</template>

<script>
import CommunityList from './components/CommunityList.vue'
import {
  queryCommunityList
} from '../../services/community'
export default {
  name: 'Communitys',
  components: { CommunityList },
  data () {
    return {
      params: {
        name: '',
        pageSize: 100,
        pageNumber: 1
      },
      list: []
    }
  },
  methods: {
    async getList () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 2)
      const resp = await queryCommunityList(this.params)
      this.$emit('changeLoading', false)
      if (!resp.success) return
      this.list = resp.result.records || []
    },
    async searchById (name) {
      if (!name) return this.getList()
      const params = {
        name: name,
        pageSize: 100,
        pageNumber: 1
      }
      const resp = await queryCommunityList(params)
      if (!resp.success) return
      const list = resp.result.records || []
      if (list.length > 1 || list.length === 0) {
        this.list = list
      } else {
        const item = list[0]
        this.$refs.communityList.handleToDetail(item)
      }
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped lang="scss">
.list-wrap {
  padding-bottom: 80px;
  box-sizing: border-box;
}
</style>
